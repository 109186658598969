.home-page-submit-container button {
    font-size: 16px;
    font-weight: 600;
    height: 52px;
    background-color: #ff4f17;
    border-radius: 6px;
    transition: all 0.2s ease;
    &:hover {
        background-color: #d4581d;
    }
}

.home-page-submit-disabled button {
    font-size: 16px;
    font-weight: 600;
    height: 52px;
    border-radius: 6px;
    background-color: #e6e6e6;
    &:hover {
        background-color: #e6e6e6;
    }
}
