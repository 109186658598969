.statement {
    color: var(--Neutral-Primary, #1a1a1a);

    /* Paragraph/P1 */
    // font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
}
.semicircle {
    width: 178px;
    height: 89px;
    transform: rotate(-158.396deg);
    flex-shrink: 0;
    border-radius: 20px 20px 150px 150px;
    background: var(--Clear-Orange-900, #331005);
    opacity: 0.3;
    position: absolute;
    mix-blend-mode: overlay;
    right: 569px;
    top: -21.11px;
}
.square {
    width: 141px;
    height: 140px;
    transform: rotate(-37.02deg);
    flex-shrink: 0;
    border-radius: 20px;
    background: #501414;
    mix-blend-mode: overlay;
    position: absolute;
    left: 601px;
    top: -47.11px;
    z-index: -1;
}

.circle {
    width: 112px;
    height: 112px;
    border-radius: 56px;
    background: #501414;
    opacity: 0.7;
    mix-blend-mode: overlay;
    position: absolute;
    left: 551px;
    top: 24px;
    z-index: -1;
}
.learn {
    color: var(--Trip-Blue-500, #0f6bff);

    /* Hyperlink/L1 */
    // font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
