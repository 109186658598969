:root {
    --arrow-value: 5px;
}
.awssld {
    --organic-arrow-thickness: 3px;
    --organic-arrow-height: 8px;
    --slider-height-percentage: 51%;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-opacity: 0.5;
    --control-button-hover-opacity: 0.75;
    --control-button-background: transparent;
    --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
    --slider-transition-duration: 575ms;
    --organic-arrow-color: #6a6a6a;
    --organic-arrow-border-radius: 0;
    --control-bullet-color: #6a6a6a;
    --content-background-color: transparent !important;
}
div.awssld {
    --slider-height-percentage: 51%;
}
.awssld__bullets {
    bottom: -1px !important;
    z-index: 9;
}
.awssld__controls {
    position: relative;
    top: 39px;
    // margin-right: calc(50% - 24%);
    margin-left: 28px;
    margin-right: 28px;
}
.awssld {
    --organic-arrow-thickness: 3px !important;
    --organic-arrow-height: 8px !important;
    --loader-bar-height: 6px !important;
    --control-button-height: 8% !important;
    --control-bullet-color: #ccc !important;
    --control-bullet-active-color: #333 !important;
    height: 200px !important;
}
.awssld__bullets button {
    width: 6px !important;
    height: 6px !important;
    background: #dbdbdb !important;
    border-radius: 30px !important;
}
.awssld__bullets .awssld__bullets--active {
    width: 12px !important;
    height: 6px !important;
    background: #b3b3b3 !important;
    border-radius: 30px !important;
}

.carousalImg {
    .awssld__bullets button {
        width: 6px !important;
        height: 6px !important;
        background: rgba(255, 255, 255, 0.7) !important;
        border-radius: 30px !important;
    }
    .awssld__bullets .awssld__bullets--active {
        width: 12px !important;
        height: 6px !important;
        background: #ffffff !important;
        border-radius: 30px !important;
    }
    .awssld__bullets {
        bottom: 16px !important;
        z-index: 9;
    }
    .awssld__controls {
        position: relative;
        top: 20px;
        margin-left: var(--arrow-value);
        margin-right: var(--arrow-value);
    }
}

a.weak {
    color: #85a3e0;
}
a.blue {
    color: #36c;
}
