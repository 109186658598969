.most-booked-img2 {
    border-top-right-radius: 6px;
}

.most-booked-img3 {
    border-bottom-right-radius: 6px;
}

.top-destination-overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 32.51%, rgba(0, 0, 0, 0.7) 73.54%);
    width: 184px;
    height: 183px;
    bottom: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
